import React from 'react'
import Layout from '../components/Layout'
import TeamSection from '../components/Sections/TeamSection'

const TeamPage = () => {
  return (
    <Layout>
      <TeamSection />
    </Layout>
  )
}

export default TeamPage

export { Head } from './../components/Head'
