import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import React, { Suspense } from 'react'
import { mapEdgesToNodes } from '../../lib/helpers'
import TeamMember, { TeamMemberProps } from './TeamMember'

const teamListVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      type: 'spring',
      when: 'beforeChildren',
      staggerChildren: 0.4,
    },
  },
}

const TeamList = () => {
  const teammembers: TeamMemberProps[] = mapEdgesToNodes(
    useStaticQuery(TeammembersQuery).allSanityTeammember,
  )

  return (
    <Suspense fallback={null}>
      <motion.div
        tw='flex flex-wrap overflow-hidden xl:-mx-4 justify-start'
        initial='hidden'
        animate='visible'
        variants={teamListVariants}
      >
        {teammembers.map((teammember: TeamMemberProps) => (
          <TeamMember {...teammember} key={teammember.name} />
        ))}
      </motion.div>
    </Suspense>
  )
}

export default TeamList

const TeammembersQuery = graphql`
  query TeammembersQuery {
    allSanityTeammember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          name
          quote
          primaryImage {
            asset {
              gatsbyImageData(width: 600, placeholder: BLURRED)
            }
            alt
          }
          secondaryImage {
            asset {
              gatsbyImageData(width: 600, placeholder: BLURRED)
            }
            alt
          }
        }
      }
    }
  }
`
