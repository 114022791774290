import { AnimatePresence, motion } from 'framer-motion'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { Image } from '../../lib/interfaces/Image'
import ParticlesContainer from '../ParticlesContainer'

export interface TeamMemberProps {
  name: string
  quote: string
  primaryImage: Image
  secondaryImage: Image
}

const teamMemberVariants = {
  hidden: {
    opacity: 0,
    y: 100,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
}

const TeamMember = ({ name, quote, primaryImage, secondaryImage }: TeamMemberProps) => {
  const [isOnHover, setIsOnHover] = useState(false)

  return (
    <motion.div
      tw='flex flex-col items-center justify-center w-full lg:w-1/2 my-4 px-4 overflow-hidden'
      variants={teamMemberVariants}
    >
      <div tw='flex flex-col w-full h-full bg-turquoiseP items-center overflow-hidden relative'>
        <ParticlesContainer id={`${name}Particles`} color='#ffffff' />
        <AnimatePresence exitBeforeEnter>
          {isOnHover ? (
            <motion.div
              key={name + '0'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onHoverStart={() => setIsOnHover(true)}
              onHoverEnd={() => setIsOnHover(false)}
              onMouseEnter={() => setIsOnHover(true)}
              onMouseLeave={() => setIsOnHover(false)}
              tw='object-cover h-auto md:h-[500px] w-auto z-10'
            >
              <GatsbyImage
                tw='w-full h-full'
                image={getImage(secondaryImage.asset)!}
                alt={secondaryImage.alt}
              />
            </motion.div>
          ) : (
            <motion.div
              key={name + '0'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onHoverStart={() => setIsOnHover(true)}
              onHoverEnd={() => setIsOnHover(false)}
              onMouseEnter={() => setIsOnHover(true)}
              onMouseLeave={() => setIsOnHover(false)}
              tw='object-cover h-auto md:h-[500px] w-auto z-10'
            >
              <GatsbyImage
                tw='w-full h-full'
                image={getImage(primaryImage.asset)!}
                alt={primaryImage.alt}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div tw='flex flex-col w-full mt-2 text-center'>
        <div tw='text-xl'>{name}</div>
        <div tw='text-sm mt-2'>{quote}</div>
      </div>
    </motion.div>
  )
}

export default TeamMember
