import React from 'react'
import Section from '../Section/Section'
import SectionTitle from '../Section/SectionTitle'
import TeamList from '../Team/TeamList'

const TeamSection = () => {
  return (
    <Section backgroundColor='white'>
      <div tw='text-center mb-8 md:mb-14 lg:mb-24'>
        <SectionTitle title='Who are we?' color='gray' />
        <p tw='max-w-5xl mx-auto leading-loose text-xl'>
          "Alone we can do so little; together we can do so much." – Helen Keller
        </p>
      </div>
      <TeamList />
    </Section>
  )
}

export default TeamSection
